<template>
  <div class="page-content">
    <page-breadcrumb title="Prompts" class="mb-2" />

    <data-create-popup title="Create Group"
      :schema="create_schema" :default-data="{}"
      :create-data-fn="createItem" @create="$refs.item_list.getList()"
    />
    <data-table-ssr id="item_list" ref="item_list"
      :columns="fields" :get-list-fn="getList"
      :edit-row-fn="updateItem" :delete-row-fn="deleteItem"
    />
  </div>
</template>

<script>
import service from '../service'

const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', validate: { required: true } },
      { label: 'Content', field: 'content', input_type: 'textarea', validate: { required: true } },
      { label: 'Bot name', field: 'bot_name', input_type: 'text' },
      { label: 'Human name', field: 'human_name', input_type: 'text' },
    ]
  }
];

const fields = [
  { label: 'Title', field: 'title', editable: true },
  { label: 'Content', field: 'content', input_type: 'textarea', editable: true },
  { label: 'Bot name', field: 'bot_name', input_type: 'text', editable: true },
  { label: 'Human name', field: 'human_name', input_type: 'text', editable: true },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
];

export default {
  data(){
    return{
      create_schema,
      fields,
      total: 0,
    }
  },
  methods:{
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
      this.$refs.item_list.getList();
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
      this.$refs.item_list.getList();
    },
  }
}
</script>
